<template>
  <div>
    <gSwiperCommon :bannerType="'show.page.home.pc'"></gSwiperCommon>
    <div v-hasRole="'L1Demand-L2List'">
      <div class="demand">
        <div class="demand-l">
          <div class="demand-l-top">
            <div class="title">
              <span>免费发布供需</span>
            </div>
          </div>
          <div class="demand-l-center">
            <div class="category">
              <div :class="cageId == index ? 'is-active' : ''" :key="index" @click="changeCategory(index)" class="category-item" v-for="(item, index) in categoryList">{{ item }}</div>
            </div>
            <div style="margin-bottom: 12px;" v-if="cageId === 0">
              <Input :placeholder="`请添加您想${cageId === 0 ? '采购' : '供应'}的产品`" v-model="productName" />
            </div>
            <div style="margin-bottom: 12px;" v-else>
              <Select v-model="productName" style="width:270px">
                <Option v-for="item in proList" :value="item.name" :key="item.name">{{ item.name }}</Option>
              </Select>
            </div>
            <div class="textarea">
              <Input :rows="4" :placeholder="`请详细描述您需要${cageId === 0 ? '采购' : '供应'}的产品`" type="textarea" v-model="content" />
            </div>
            <div class="upload-item">
              <div class="my-upload">
                <!-- <div :key="'upload' + index" class="my-upload-list" v-for="(item, index) in uploadList">
                  <template v-if="item.status === 'finished'">
                    <img :src="item.url" style="width: 46px; height: 46px" />
                    <div class="my-upload-list-cover">
                      <Icon @click.native="handleView(item.url)" type="ios-eye-outline"></Icon>
                      <Icon @click.native.stop.prevent="handleRemove(item)" type="ios-trash-outline"></Icon>
                    </div>
                  </template>
                  <template v-else>
                    <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
                      <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
                    </Spin>
                  </template>
                </div> -->
                <div>
                  <uploadNeed :fileList="uploadBanner" @upload-ok="bannerOK" />
                </div>
                <!-- <Upload
                  :action="uploadUrl"
                  :before-upload="handleBeforeUpload"
                  :default-file-list="defaultList"
                  :format="['jpg', 'jpeg', 'png']"
                  :headers="formHeaders"
                  :max-size="2048"
                  :on-exceeded-size="handleMaxSize"
                  :on-format-error="handleFormatError"
                  :on-success="handleSuccess"
                  :show-upload-list="false"
                  ref="upload"
                  style="display: inline-block; position: relative"
                  type="drag"
                  v-show="uploadList && uploadList.length == 0"
                >
                  <div style="width: 46px; height: 46px; color: rgb(133, 133, 133)">
                    <div style="font-size: 16px">
                      +
                      <p style="font-size: 14px">上传</p>
                    </div>
                  </div>
                </Upload> -->

                <!-- <div :key="'upload2' + index2" class="my-upload-list" style="position: relative" v-for="(item2, index2) in uploadList2">
                  <template v-if="item2.status === 'finished'">
                    <img :src="item2.url" style="width: 46px; height: 46px" />
                    <div class="my-upload-list-cover">
                      <Icon @click.native="handleView(item2.url)" type="ios-eye-outline"></Icon>
                      <Icon @click.native.stop.prevent="handleRemove2(item2)" type="ios-trash-outline"></Icon>
                    </div>
                  </template>
                  <template v-else>
                    <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
                      <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
                    </Spin>
                  </template>
                </div>
                <Upload
                  :action="uploadUrl"
                  :before-upload="handleBeforeUpload2"
                  :default-file-list="defaultList2"
                  :format="['jpg', 'jpeg', 'png']"
                  :headers="formHeaders"
                  :max-size="2048"
                  :on-exceeded-size="handleMaxSize"
                  :on-format-error="handleFormatError2"
                  :on-success="handleSuccess2"
                  :show-upload-list="false"
                  ref="upload2"
                  style="display: inline-block"
                  type="drag"
                  v-show="uploadList2 && uploadList2.length < 1"
                >
                  <div style="width: 46px;height: 46px; color: rgb(133, 133, 133);">
                    <div style="font-size: 16px">
                      +
                      <p style="font-size: 14px">上传</p>
                    </div>
                  </div>
                </Upload>

                <div :key="'upload3' + index3" class="my-upload-list" style="position: relative" v-for="(item3, index3) in uploadList3">
                  <template v-if="item3.status === 'finished'">
                    <img :src="item3.url" style="width: 46px; height: 46px" />
                    <div class="my-upload-list-cover">
                      <Icon @click.native="handleView(item3.url)" type="ios-eye-outline"></Icon>
                      <Icon @click.native.stop.prevent="handleRemove3(item3)" type="ios-trash-outline"></Icon>
                    </div>
                  </template>
                  <template v-else>
                    <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
                      <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
                    </Spin>
                  </template>
                </div>
                <Upload
                  :action="uploadUrl"
                  :before-upload="handleBeforeUpload3"
                  :default-file-list="defaultList3"
                  :format="['jpg', 'jpeg', 'png']"
                  :headers="formHeaders"
                  :max-size="2048"
                  :on-exceeded-size="handleMaxSize"
                  :on-format-error="handleFormatError3"
                  :on-success="handleSuccess3"
                  :show-upload-list="false"
                  ref="upload3"
                  style="display: inline-block"
                  type="drag"
                  v-show="uploadList3 && uploadList3.length < 1"
                >
                  <div style="width: 46px;height: 46px; color: rgb(133, 133, 133);">
                    <div style="font-size: 16px">
                      +
                      <p style="font-size: 14px">上传</p>
                    </div>
                  </div>
                </Upload>

                <div :key="'upload4' + index4" class="my-upload-list" style="position: relative" v-for="(item4, index4) in uploadList4">
                  <template v-if="item4.status === 'finished'">
                    <img :src="item4.url" style="width: 46px; height: 46px" />
                    <div class="my-upload-list-cover">
                      <Icon @click.native="handleView(item4.url)" type="ios-eye-outline"></Icon>
                      <Icon @click.native.stop.prevent="handleRemove4(item4)" type="ios-trash-outline"></Icon>
                    </div>
                  </template>
                  <template v-else>
                    <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
                      <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
                    </Spin>
                  </template>
                </div>
                <Upload
                  :action="uploadUrl"
                  :before-upload="handleBeforeUpload4"
                  :default-file-list="defaultList4"
                  :format="['jpg', 'jpeg', 'png']"
                  :headers="formHeaders"
                  :max-size="2048"
                  :on-exceeded-size="handleMaxSize"
                  :on-format-error="handleFormatError4"
                  :on-success="handleSuccess4"
                  :show-upload-list="false"
                  ref="upload4"
                  style="display: inline-block"
                  type="drag"
                  v-show="uploadList4 && uploadList4.length < 1"
                >
                  <div style="width: 46px;height: 46px; color: rgb(133, 133, 133);">
                    <div style="font-size: 16px">
                      +
                      <p style="font-size: 14px">上传</p>
                    </div>
                  </div>
                </Upload>

                <div :key="'upload5' + index5" class="my-upload-list" style="position: relative" v-for="(item5, index5) in uploadList5">
                  <template v-if="item5.status === 'finished'">
                    <img :src="item5.url" style="width: 46px; height: 46px" />
                    <div class="my-upload-list-cover">
                      <Icon @click.native="handleView(item5.url)" type="ios-eye-outline"></Icon>
                      <Icon @click.native.stop.prevent="handleRemove5(item5)" type="ios-trash-outline"></Icon>
                    </div>
                  </template>
                  <template v-else>
                    <Spin style="width: 46px;height: 46px;position: absolute;top: 0;">
                      <Icon class="demo-spin-icon-load" size="20" type="ios-loading"></Icon>
                    </Spin>
                  </template>
                </div>
                <Upload
                  :action="uploadUrl"
                  :before-upload="handleBeforeUpload5"
                  :default-file-list="defaultList5"
                  :format="['jpg', 'jpeg', 'png']"
                  :headers="formHeaders"
                  :max-size="2048"
                  :on-exceeded-size="handleMaxSize"
                  :on-format-error="handleFormatError5"
                  :on-success="handleSuccess5"
                  :show-upload-list="false"
                  ref="upload5"
                  style="display: inline-block"
                  type="drag"
                  v-show="uploadList5 && uploadList5.length < 1"
                >
                  <div style="width: 46px;height: 46px; color: rgb(133, 133, 133);">
                    <div style="font-size: 16px">
                      +
                      <p style="font-size: 14px">上传</p>
                    </div>
                  </div>
                </Upload> -->

                <iModal cancel-text title="图片查看" v-model="visible">
                  <div style="text-align: center">
                    <img :src="imgName" style="max-width: 100%" v-if="visible" />
                  </div>
                </iModal>
              </div>
            </div>
            <div @click="supplydemand" class="submit">发布供需</div>
          </div>
          <div class="demand-l-bottom" v-if="!demandList">
            <div class="news-item">
              <div :key="index" class="news" v-for="(item, index) in demandList">
                <div style="display: flex; align-items: center" v-if="index < 3">
                  <span class="span1">最新</span>
                  <span class="span2">{{ item.content }}</span>
                  <span class="time">{{ item.auditTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--供需商机-->
        <div class="demand-r">
          <div class="demand-r-top">
            <div class="demand-Tabs">
              <!-- <span :class="categoryindex == 0 ? 'is-active' : ''" @click="categoryTabs(0)" class="tabs-span">采购产品({{ typetotalCount1 }})</span>
              <div class="tabs-fenge"></div>
              <span :class="categoryindex == 1 ? 'is-active' : ''" @click="categoryTabs(1)" class="tabs-span">供应产品({{ typetotalCount2 }})</span>
              <div class="tabs-fenge"></div> -->
              <span :class="categoryindex == 1 ? 'is-active' : ''" @click="categoryTabs(1)" class="tabs-span">供应产品({{ typetotalCount2 }}) </span>
              <div class="tabs-fenge"></div>
              <span :class="categoryindex == 0 ? 'is-active' : ''" @click="categoryTabs(0)" class="tabs-span">采购产品({{ typetotalCount1 }}) </span>
              <div class="tabs-fenge"></div>
            </div>
          </div>
          <div class="demand-r-list" v-if="demandList && demandList.length">
            <!--供需详情-->
            <div :key="index" class="supply" v-for="(item, index) in demandList">
              <div :title="item.content" style="padding: 10px" @click.stop="linkToDemandDetail({id:item.id,isFollow:item.isFollow })">
                <div class="supply-top">
                  <img :src="item.publisher.avatarUrl" class="headerimg" v-load="'oPicXS'" v-if="item.publisher && item.publisher.avatarUrl" />
                  <img class="headerimg" :src="exhibitionInfo.logo" v-else-if="categoryindex==1&&item.publisherType==1" />
                  <img class="headerimg" src="@/assets/img/user.png" v-else-if="categoryindex==0" />
                  <div>
                    <div class="name" v-if="item.publisher&&item.publisherType==0">
                      <span :title="item.publisher.publisherName" class="name1">{{ item.publisher.publisherName }}</span>
                      <span :title="item.publisher.jobTitle" class="name2" style>{{ item.publisher.jobTitle }}</span>
                    </div>
                    <div class="name" v-else-if="item.publisher&&item.publisherType==1">
                      <div :title="item.publisher.publisherName" class="name1">{{ item.publisher.publisherName }}</div>

                    </div>
                    <div class="company" v-if="item.publisherType==0">{{ item.publisher && item.publisher.compName }}</div>
                    <div class="company" v-else>{{item.publisher &&item.publisher.booth}}</div>
                    <!-- <div class="company" v-if="item.member && item.member.phone && categoryindex === 1">{{ item.member && item.member.phone }}</div>
                    <div class="company" v-if="item.member && item.member.email && categoryindex === 1">{{ item.member && item.member.email }}</div> -->
                  </div>

                  <div @click.stop="supplyfollower(item)" class="guanzhu" v-if="!item.isFollow && SupplymemberId && item.memberId != userInfo.inMember.memberId && item.publisher">关注</div>
                  <div @click.stop="deSupplyFollower(item)" class="yiguanzhu" v-if="item.isFollow && SupplymemberId && item.memberId != userInfo.inMember.memberId">已关注</div>
                </div>
                <div class="supply-center">
                  <div class="category-content">
                    <span class="category" v-if="item.type">{{ item.type }}</span>
                    <span :title="item.productName" class="category-text">{{ item.productName }}</span>
                  </div>
                  <div :title="item.content" class="category-text2" v-if="item.content" v-html="item.content ">

                  </div>
                </div>
                <div class="supply-bottom">
                  <div class="video_wrap" v-if="item.videos">
                    <div class="top_video" v-for="(video,ind) in item.videos" :key="ind">
                      <div class="video_mask">
                        <i class="iconfont icon-shipinmianxing"></i>
                      </div>
                      <img :src="video.cover">
                    </div>
                  </div>
                  <div @click="imgClick(item.imgUrls)" @click.stop="show = true" v-else-if="item.imgUrls&&item.imgUrls.length">
                    <img :src="item.imgUrls[0]" class="img1" v-if="item.imgUrls[0]" v-load="'oPicXS'" />
                    <img :src="item.imgUrls[1]" class="img2" v-if="item.imgUrls[1]" v-load="'oPicXS'" />
                    <img :src="item.imgUrls[2]" class="img3" v-if="item.imgUrls[2]" v-load="'oPicXS'" />
                    <img :src="item.imgUrls[3]" class="img4" v-if="item.imgUrls[3]" v-load="'oPicXS'" />
                    <img :src="item.imgUrls[4]" class="img5" v-if="item.imgUrls[4]" v-load="'oPicXS'" />
                  </div>
                  <div class="empty" v-else>
                    <div class="empty-img">无图片</div>
                  </div>
                  <div class="time">
                    <div>
                      <span>{{ item.viewCount }} 阅读</span>
                      <span style="margin-left: 10px; margin-bottom: 10px">{{ item.commentCount }}评论</span>
                    </div>
                    <div>{{ item.updateAt }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="demand-r-list" v-else>
            <div style="text-align: center; margin-top: 200px; width: 100%;">暂无数据</div>
          </div> -->
          <div class="demand-r-list" v-if="loading">
            <div style="text-align: center; margin-top: 200px; width: 100%;">数据加载中...</div>
          </div>
          <div class="demand-r-list" v-else-if="!loading && !demandList.length">
            <div style="text-align: center; margin-top: 200px; width: 100%;">暂无数据</div>
          </div>
          <div class="my-page" v-if="totalCount > size">
            <Page :current="page" :page-size="size" :total="totalCount" @on-change="changePage" @on-page-size-change="changePageSize" show-elevator show-total />
          </div>
        </div>
      </div>
    </div>

    <Supplyqrcode ref="showcode"></Supplyqrcode>

    <needsModel ref="needsModel"></needsModel>
    <ShowSwiper :imgList="imgList" :show="show"></ShowSwiper>
  </div>
</template>

<script>
import gSwiper from "@components/gSwiper/gSwiper.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import QRCode from "qrcodejs2";
import ShowSwiper from "@components/ShowSwiper/ShowSwiper";
import needsModel from "../../../components/needsModel";
import Supplyqrcode from "@components/Supplyqrcode/index.vue";
import gSwiperCommon from "@components/gSwiperCommon/gSwiperCommon.vue";
import uploadNeed from "@components/upLoad/upLoadNeed";
export default {
  name: "demand",
  data () {
    return {
      length: 0,
      categoryList: ["采购产品", "供应产品"],
      imgList: [],
      SuppluImgList: [], //供需上传图片数组
      guanzhu: false,
      cageId: 0, //供需类别索引
      content: "", //供需内容
      productName: "", // 采购的采购产品
      uploadList: [],
      // uploadList2: [],
      // uploadList3: [],
      // uploadList4: [],
      // uploadList5: [],
      defaultList: [],
      // defaultList2: [],
      // defaultList3: [],
      // defaultList4: [],
      // defaultList5: [],
      visible: false,
      imgName: "",
      value6: "",
      supplyId: "",
      show: false,
      bannerList: [
        "https://images.unsplash.com/photo-1602809096240-0c89916a2676?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=400&ixlib=rb-1.2.1&q=80&w=1920",
        "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1240888316,1729450859&fm=26&gp=0.jpg",
      ],
      page: 1,
      size: 12,
      qrcode: "",
      typetotalCount1: '-',
      typetotalCount2: '-',
      typetotalCount3: '-',
      showNeeds: false,
      totalCount: 0, //供需总条数
      demandList: [], //供需列表
      categoryindex: 1, //选中的类别
      SupplymemberId: "",
      query: "",
      proList: [],
      uploadBanner: [],
      loading: true,
    };
  },
  components: {
    gSwiper,
    gSwiperCommon,
    ShowSwiper,
    needsModel,
    Supplyqrcode,
    uploadNeed
  },
  computed: {
    ...mapGetters({
      searchType: "getSearchType",
      searchTxt: "getSearchTxt",
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      formHeaders: "getFormHeaders",
      uploadUrl: "getUploadUrl",
      exhibitionInfo: "getExhibitionInfo",
      login: "getLogin",
      demandPage: 'getDemandPage',
      demandType: 'getDemandType'
    }),
    isRegOk () {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
    userRole () {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole ? this.userInfo.inMember.userRole : "";
    },
  },
  watch: {
    searchTxt (val, oldVal) {
      this.$store.commit('setDemandPage', 1)
      this.page = 1;
      this.init();
      this.getCount();
    },
  },
  created () {
    document.title = this.exhibitionInfo ? `供需列表-${this.exhibitionInfo.name}` : "展商秀";
    if (this.demandPage) {
      this.page = this.demandPage;
    }
    if (this.demandType == '供应产品') {
      this.categoryTabs(1, true);
    } else {
      this.categoryTabs(0, true);
    }
    this.getCount();
    this.getproduct()
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      supplyDemand: "news/supplyDemand",
      supplyFollower: "news/supplyFollower",
      deleteSupplyFollower: "news/deleteSupplyFollower",
      unverifiedDialog: "unverifiedDialog",
      linkToDemandDetail: "linkTo/linkToDemandDetail"
    }),
    async getproduct () {
      if (this.userRole == "EXHIBITOR") {
        let query = `
        query{
          exhibitorQuery{
            get(id:"${this.userInfo.inMember.exhibitorId}"){
                products(num:10000){
                  name
                  id
                  nameEn 
                }
              }
            }
        }
      `;
        let opt = {
          query: query,
          variables: {},
        };
        let res = await this.graphqlPost(opt);
        console.log(res, "exinfo res");
        this.proList = res.data.exhibitorQuery.get.products
      }
    },
    async getCount () {
      let query = `
      query($query:QueryInput!){
        supplyDemandQuery{
        query(query:$query){
          totalCount
          }
        }
      }
      `;
      let EqualContent = {};
      if (this.searchType === "4") {
        EqualContent = this.searchTxt ? { Like: { n: "content", v: `%${this.searchTxt}%` } } : {};
      }
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal1: {
          n: "auditState",
          v: 1,
        },
        Equal3: {
          n: "type",
          v: this.categoryList[0],
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
        ...EqualContent,
      };
      let where1 = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal1: {
          n: "auditState",
          v: 1,
        },
        Equal3: {
          n: "type",
          v: this.categoryList[1],
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
        ...EqualContent,
      };
      let where2 = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal1: {
          n: "auditState",
          v: 1,
        },
        Equal3: {
          n: "type",
          v: this.categoryList[2],
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
        ...EqualContent,
      };
      let order = [{ N: "updateAt", v: 0 }];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.page,
            size: this.size,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let opt1 = {
        query: query,
        variables: {
          query: {
            page: this.page,
            size: this.size,
            where: JSON.stringify(where1),
            order: JSON.stringify(order),
          },
        },
      };
      let opt2 = {
        query: query,
        variables: {
          query: {
            page: this.page,
            size: this.size,
            where: JSON.stringify(where2),
            order: JSON.stringify(order),
          },
        },
      };

      let data = await this.graphqlPost(opt);
      let data2 = await this.graphqlPost(opt1);
      let data3 = await this.graphqlPost(opt2);
      this.typetotalCount1 = data.data.supplyDemandQuery.query.totalCount;
      this.typetotalCount2 = data2.data.supplyDemandQuery.query.totalCount;
      this.typetotalCount3 = data3.data.supplyDemandQuery.query.totalCount;
    },
    async init () {
      this.loading = true;
      if (!this.isRegOk) {
        this.SupplymemberId = "";
        this.query = `
        query($query:QueryInput!){
          supplyDemandQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items {
                  content
                  imgUrls
                  videos
                  memberId 
                  productName 
                  publisherType
                  viewCount
                  updateAt
                  type
                  commentCount
                  id
                  auditTime
                  member{
                    id
                    avatarUrl
                    company
                    jobTitle
                    name 
                    phone
                    email
                  }
                  publisher{
                    avatarUrl
                    compName
                    compNameEn
                    email
                    jobTitle
                    phoneNumber
                    publisherName
                    publisherNameEn
                    publishId
                    booth
                  }
                }
              }
            }
        }
      `;
      } else {
        this.SupplymemberId = this.userInfo.inMember.memberId;
        this.query = `
        query($query:QueryInput!){
          supplyDemandQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items {
                  content
                  productName
                  imgUrls
                  videos
                  memberId
                  isFollow(memberId:"${this.userInfo.inMember.memberId}")
                  publisherType
                  viewCount
                  updateAt
                  type
                  commentCount
                  id
                  auditTime
                  member{
                    id
                    avatarUrl
                    company
                    jobTitle
                    name
                    phone
                    email
                  }
                   publisher{
                    avatarUrl
                    compName
                    compNameEn
                    email
                    jobTitle
                    phoneNumber
                    publisherName
                    publisherNameEn
                    publishId
                    booth
                  }
                }
              }
            }
        }
      `;
      }

      let EqualContent = {};
      if (this.searchType === "4") {
        EqualContent = this.searchTxt ? { Like: { n: "content", v: `%${this.searchTxt}%` } } : {};
      }
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal1: {
          n: "auditState",
          v: 1,
        },
        Equal2: {
          n: "type",
          v: this.categoryList[this.categoryindex],
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
        ...EqualContent,
      };
      let order = [{ N: "updateAt", v: 0 }];
      let opt = {
        query: this.query,
        variables: {
          query: {
            page: this.page,
            size: this.size,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };

      let data = await this.graphqlPost(opt);
      this.totalCount = data.data.supplyDemandQuery.query.totalCount;
      this.demandList = data.data.supplyDemandQuery.query.items;
      if (this.demandList) {
        this.demandList.forEach((c) => {
          if (c.imgUrls) {
            c.imgUrls = c.imgUrls.split(";");
          }
          if (c.videos) {
            c.videos = JSON.parse(c.videos)
          }
          c.auditTime = moment(c.auditTime).format("MM/DD");
        });
      }
      this.loading = false;
    },
    //切换供需类别
    categoryTabs (index, isCreated = false) {
      this.categoryindex = index;
      if (index) {
        this.$store.commit('setDemandType', '供应产品');
      } else {
        this.$store.commit('setDemandType', '采购产品');
      }
      if (!isCreated) {
        this.$store.commit('setDemandPage', 1)
        this.page = 1;
      }
      this.init();
    },
    changeCategory (index) {
      if (this.categoryList[index] === "供应产品" && this.userRole !== "EXHIBITOR") {
        this.$Message.warning("仅展商可发布供应信息!");
        return;
      }
      this.cageId = index;
    },
    closeSwiper () {
      this.show = false;
    },

    goQrCode (id) {
      if (id) {
        this.$refs.showcode.open(id);
      }
    },
    //发布供需
    async supplydemand (item) {
      if (!this.login || !this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if (this.userInfo && this.userInfo.inMember.isBlacklist) {
        this.$Message.warning('您已被屏蔽');
        return
      }
      if (!this.getRoleHandle('L1Demand-L2List-L3List')) {
        return
      }
      if (!this.content) {
        this.$Message.warning("请输入供需内容");
        return;
      }
      if (!this.productName) {
        this.$Message.warning("请添加您想采购的采购产品");
        return;
      }
      if (this.cageId == -1) {
        this.$Message.warning("请选择供需类型");
        return;
      }
      this.content = this.content.split("\n").join("<br>");
      let opt = {
        memberId: this.userInfo.inMember.memberId,
        type: this.categoryList[this.cageId],
        publisherType: 0,
        exhibitionId: this.exhibitionId,
        content: this.content,
        productName: this.productName,
      };
      if (this.uploadBanner && this.uploadBanner.length) {
        if (this.uploadBanner[0].cover) {
          opt.videos = `[{"cover":"${this.uploadBanner[0].cover}","url":"${this.uploadBanner[0].url}","duration":"","coverIndex": 5}]`
        } else {
          let arr = []
          this.uploadBanner.forEach(e => {
            arr.push(e.url);
          })
          opt.imgUrls = arr.join(";");
        }
      }
      let data = await this.supplyDemand(opt);
      if (data.result) {
        this.$Message.success("提交成功");
        this.content = "";
        this.productName = "";
        this.uploadBanner = [];
        // this.uploadList2 = [];
        // this.uploadList3 = [];
        // this.uploadList4 = [];
        // this.uploadList5 = [];
        this.cageId = -1;
      } else {
        this.$Message.warning("提交失败");
      }
    },
    //供需关注
    async supplyfollower (item) {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      let userInfo = {
        name: this.userInfo.name,
        avatarUrl: this.userInfo.avatarUrl,
        nickName: this.userInfo.nickName,
      };
      let opt = {
        supplyDemandId: item.id,
        memberId: this.userInfo.inMember.memberId,
        userInfo: JSON.stringify(userInfo),

      };
      let data = await this.supplyFollower(opt);
      console.log(data);
      if (data.result) {
        this.init();
      } else {
        this.$Message.warning("关注失败");
      }
    },
    //取消关注
    async deSupplyFollower (item) {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      let opt = {
        supplyDemandId: item.id,
        memberId: this.userInfo.inMember.memberId,
      };
      let data = await this.deleteSupplyFollower(opt);
      console.log(data);
      if (data.result) {
        this.init();
      } else {
        this.$Message.warning("取消关注失败");
      }
    },
    changePage (e) {
      this.page = e;
      this.$store.commit('setDemandPage', e)
      this.init();
    },
    changePageSize (e) {
      this.size = e;
    },
    bannerOK (list) {
      this.uploadBanner = list;
    },
    // 查看上传
    handleView (name) {
      this.imgName = name;
      this.visible = true;
    },
    // // 移除上传
    // handleRemove2(file) {
    //   const fileList = this.uploadList2;
    //   this.uploadList2.splice(fileList.indexOf(file), 1);
    //   this.SuppluImgList.splice(fileList.indexOf(file), 1);
    // },

    // // 上传成功
    // handleSuccess2(res, file) {
    //   if (res && res.data.length) {
    //     file.url = res.data[0];
    //     this.SuppluImgList.push(file.url);
    //     this.uploadList2 = this.$refs.upload2.fileList;
    //   }
    // },
    // // 文件格式错误
    // handleFormatError2(file) {
    //   this.$Message.warning(`文件格式是不正确的, 请上传jpg或者png格式的图片`);
    // },
    // // 上传数量
    // handleBeforeUpload2() {
    //   const limitNum = 100;
    //   this.uploadList2.push({ status: "start" });
    //   const check = this.uploadList2.length < limitNum;
    //   if (!check) {
    //     this.$Message.warning(`最多上传${limitNum}张图片`);
    //   }
    //   return check;
    // },
    // 移除上传
    handleRemove (file) {
      const fileList = this.uploadList;
      this.uploadList.splice(fileList.indexOf(file), 1);
      this.SuppluImgList.splice(fileList.indexOf(file), 1);
      console.log(this.uploadList, "ohuseiufhwuihf");
    },

    // 上传成功
    handleSuccess (res, file) {
      if (res && res.data.length) {
        file.url = res.data[0];
        this.uploadList.status = "finished";
        this.SuppluImgList.push(file.url);
        this.uploadList = this.$refs.upload.fileList;
        console.log(this.uploadList, "upload");
      }
    },
    // 文件格式错误
    handleFormatError (file) {
      this.$Message.warning(`文件格式是不正确的, 请上传jpg或者png格式的图片`);
    },
    // 上传数量
    handleBeforeUpload () {
      this.uploadList.push({ status: "start" });
      const limitNum = 100;
      const check = this.uploadList.length < limitNum;
      if (!check) {
        this.$Message.warning(`最多上传${limitNum}张图片`);
      }
      return check;
    },

    // // 移除上传
    // handleRemove3(file) {
    //   const fileList = this.uploadList3;
    //   this.uploadList3.splice(fileList.indexOf(file), 1);
    //   this.SuppluImgList.splice(fileList.indexOf(file), 1);
    // },

    // // 上传成功
    // handleSuccess3(res, file) {
    //   if (res && res.data.length) {
    //     file.url = res.data[0];
    //     this.SuppluImgList.push(file.url);
    //     this.uploadList3 = this.$refs.upload3.fileList;
    //   }
    // },
    // // 文件格式错误
    // handleFormatError3(file) {
    //   this.$Message.warning(`文件格式是不正确的, 请上传jpg或者png格式的图片`);
    // },
    // // 上传数量
    // handleBeforeUpload3() {
    //   const limitNum = 100;
    //   this.uploadList3.push({ status: "start" });
    //   const check = this.uploadList3.length < limitNum;
    //   if (!check) {
    //     this.$Message.warning(`最多上传${limitNum}张图片`);
    //   }
    //   return check;
    // },

    // // 移除上传
    // handleRemove4(file) {
    //   const fileList = this.uploadList4;
    //   this.uploadList4.splice(fileList.indexOf(file), 1);
    //   this.SuppluImgList.splice(fileList.indexOf(file), 1);
    // },

    // // 上传成功
    // handleSuccess4(res, file) {
    //   if (res && res.data.length) {
    //     file.url = res.data[0];
    //     this.SuppluImgList.push(file.url);
    //     this.uploadList4 = this.$refs.upload4.fileList;
    //   }
    // },
    // // 文件格式错误
    // handleFormatError4(file) {
    //   this.$Message.warning(`文件格式是不正确的, 请上传jpg或者png格式的图片`);
    // },
    // // 上传数量
    // handleBeforeUpload4() {
    //   const limitNum = 100;
    //   this.uploadList4.push({ status: "start" });
    //   const check = this.uploadList4.length < limitNum;
    //   if (!check) {
    //     this.$Message.warning(`最多上传${limitNum}张图片`);
    //   }
    //   return check;
    // },

    // // 移除上传
    // handleRemove5(file) {
    //   const fileList = this.uploadList5;
    //   this.uploadList5.splice(fileList.indexOf(file), 1);
    //   this.SuppluImgList.splice(fileList.indexOf(file), 1);
    // },

    // // 上传成功
    // handleSuccess5(res, file) {
    //   if (res && res.data.length) {
    //     file.url = res.data[0];
    //     this.SuppluImgList.push(file.url);
    //     this.uploadList5 = this.$refs.upload5.fileList;
    //   }
    // },
    // // 文件格式错误
    // handleFormatError5(file) {
    //   this.$Message.warning(`文件格式是不正确的, 请上传jpg或者png格式的图片`);
    // },
    // // 上传数量
    // handleBeforeUpload5() {
    //   const limitNum = 100;
    //   this.uploadList5.push({ status: "start" });
    //   const check = this.uploadList5.length < limitNum;
    //   if (!check) {
    //     this.$Message.warning(`最多上传${limitNum}张图片`);
    //   }
    //   return check;
    // },
    // 文件过大
    handleMaxSize (file) {
      this.$Message.warning(`文件大小超过限制, 请上传小于2M的图片`);
    },
    imgClick (imgList) {
      this.modal4 = true;
      this.imgList = imgList;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.my-page {
  height: 50px;
  padding: 0;
  width: 100%;
  display: flex;
  background-color: #fff;
  justify-content: center;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

.carousel {
  position: relative;
  width: 100%;

  top: 50%;
  text-align: center;
  margin-top: -400px;
}

::v-deep.ivu-carousel {
  button {
    width: 72px;
    height: 72px;
  }

  .ivu-icon {
    font-size: 28px;
  }

  .ivu-carousel-dots-inside {
    display: none;
  }
}

.modal-carousel-mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.8);
  /*img{*/
  /*  */
  /*}*/
}

.category-text :hover {
}

.ivu-upload {
  width: 46px;
  height: 46px;
}

.my-upload {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.my-upload-list {
  display: inline-block;
  width: 46px;
  height: 46px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  line-height: 46px;
  background: #fff;
  position: relative;
  margin: 0;
}

.my-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.my-upload-list:hover .my-upload-list-cover {
  display: block;
}

.my-upload-list-cover i {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin: 0 2px;
}

.demand {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;

  &-Tabs {
    padding: 20px 16px;
    display: flex;
    align-items: center;

    .tabs-span {
      cursor: pointer;

      &.is-active {
        @include font_color(#1890ff);
      }
    }

    .tabs-fenge {
      width: 1px;
      height: 18px;
      background-color: #e6e6e6;
      display: inline-block;
      margin: 0 20px;
    }
  }
  .video_wrap {
    .top_video {
      width: 50px;
      height: 50px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
      .video_mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
        }
      }
    }
  }
  .empty {
    &-img {
      width: 50px;
      height: 50px;
      background-color: #fff;
      border-radius: 1px;
      border: solid 1px #ededed;
      font-size: 12px;
      color: #999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .title {
    display: flex;
    align-items: center;
    height: 18px;
    border-left: 4px solid #1890ff;
    @include border_color(#1890ff);

    span {
      padding-left: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .demand-l {
    width: 310px;

    &-top {
      height: 50px;
      border: 1px solid #ededed;
      border-bottom: none;
      display: flex;
      align-items: center;
      background-color: #fff;
    }

    &-center {
      padding: 20px;
      border: 1px solid #ededed;
      background-color: #fff;
      .category {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .category-item {
          flex: 1;
          height: 36px;
          margin-right: 10px;
          background-color: #f7f7f7;
          border-radius: 3px;
          color: #333;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 14px;
          border: solid 1px #dedede;
          &:last-child {
            margin-right: 0;
          }
          &.is-active {
            color: #fff;
            @include background_color(#1890ff);
            @include border_color(#1890ff);
          }
        }
      }

      .textarea {
        min-height: 94px;
        margin-bottom: 10px;
      }

      .upload-item {
        display: flex;
        justify-content: space-between;

        .uploadlist {
          width: 46px;
          height: 46px;
          background-color: #f7f7f7;
          border: dashed 1px #d9d9d9;
          cursor: pointer;

          span {
            width: 100%;
            display: flex;
            justify-content: center;
            color: #888;
          }
        }
      }

      .submit {
        width: 270px;
        height: 40px;
        @include background_color(#1890ff);
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    &-bottom {
      height: 130px;
      border: 1px solid #ededed;
      background-color: #fff;
      .news-item {
        padding: 0 20px;
        margin-top: 20px;

        .news {
          margin-bottom: 14px;
          display: flex;
          align-items: center;

          .span1 {
            font-size: 12px;
            @include font_color(#0190fe);
            height: 20px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            border: solid 1px #0190fe;
            @include border_color(#0190fe);
          }

          .span2 {
            width: 165px;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            margin-left: 7px;
          }

          .time {
            color: #999;
            font-size: 12px;
            margin-left: 26px;
          }
        }
      }
    }
  }

  .demand-r {
    width: 870px;
    min-height: 600px;
    background-color: #fff;
    border: solid 1px #ededed;
    margin-left: 20px;
    margin-bottom: 74px;

    &-top {
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ededed;
    }

    &-list {
      padding: 20px 0 0 20px;
      display: flex;
      flex-wrap: wrap;
    }

    .supply {
      width: 266px;
      height: 205px;
      background-color: #f7f7f7;
      border-radius: 3px;
      position: relative;
      margin-right: 16px;
      margin-bottom: 20px;

      &-top {
        display: flex;
        height: 46px;

        .headerimg {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .name {
          font-size: 14px;
          color: #333;
          .name1 {
            max-width: 120px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .name2 {
            max-width: 60px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 8px;
            font-size: 12px;
            color: #666;
          }
        }

        .company {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 190px;
          color: #666;
          font-size: 12px;
        }

        .guanzhu {
          width: 54px;
          height: 24px;
          @include background_color(#1890ff);
          border-radius: 3px;
          position: absolute;
          top: 10px;
          right: 10px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .yiguanzhu {
          width: 54px;
          height: 24px;
          background-color: #fff;
          border-radius: 3px;
          border: 1px solid #1890ff;
          @include border_color(#1890ff);
          position: absolute;
          top: 10px;
          right: 10px;
          @include font_color(#1890ff);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      &-center {
        margin-top: 5px;

        .category {
          width: 58px;
          height: 20px;
          background-color: #ffefe5;
          border-radius: 3px;
          font-size: 12px;
          color: #ff5e00;
          padding: 4px;
          margin-right: 5px;
          text-align: center;
        }

        .category-content {
          line-height: 24px;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .category-text2 {
          height: 28px;
          margin-top: 10px;
          font-size: 12px;
          color: #333;
          margin-bottom: 21px;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.2;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          span {
            color: #666;
          }
        }
      }

      &-bottom {
        margin-top: 10px;
        position: relative;
        display: flex;

        .img1 {
          width: 50px;
          height: 50px;
        }

        .img2 {
          width: 42px;
          height: 42px;
          position: absolute;
          top: 8px;
          left: 18px;
        }

        .img3 {
          width: 34px;
          height: 34px;
          position: absolute;
          top: 16px;
          left: 36px;
        }

        .img4 {
          width: 26px;
          height: 26px;
          position: absolute;
          top: 24px;
          left: 54px;
        }

        .img5 {
          width: 18px;
          height: 18px;
          position: absolute;
          top: 32px;
          left: 72px;
        }

        .time {
          font-size: 12px;
          color: #999;
          position: absolute;
          right: 0;
          top: 8px;
        }
      }
    }
  }
}
</style>
